import { useState } from 'react';
import { useSelector } from 'react-redux';
import Image from 'next/image';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import SignInForm from './sign-in-form/sign-in-form';
import ForgotPasswordForm from './forgot-password-form/forgot-password-form';
import CheckCode from './check-code/check-code';

import styles from './sign-in.module.scss';

const SignIn = () => {
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const sessionToken = useSelector((state) => state.authorization.sessionToken);

  return (
    <div className={styles['sign-in']}>
      <div className={styles['sign-in__header']}>
        {!isForgotPassword && (
          <Image src="/img/icon/logo.svg" width="155" height="28" alt="logo" />
        )}

        {isForgotPassword && (
          <div className={styles['sign-in__header-wrapper']}>
            <h2>Восстановление пароля</h2>
            <Button type="text" onClick={() => setIsForgotPassword(false)}>
              <ArrowLeftOutlined />
            </Button>
          </div>
        )}
      </div>

      {!isForgotPassword && !sessionToken && (
        <SignInForm setIsForgotPassword={setIsForgotPassword} />
      )}
      {isForgotPassword && !sessionToken && (
        <ForgotPasswordForm setIsForgotPassword={setIsForgotPassword} />
      )}
      {sessionToken && <CheckCode sessionToken={sessionToken} />}
    </div>
  );
};

export default SignIn;
