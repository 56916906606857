import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, message,
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import SignUp from '../../sign-up/sign-up';
import { FormField } from '../../../shared';

import { auth } from '../../../../services/authorization';

import styles from './sign-in-form.module.scss';

const { Password } = Input;

const SignInForm = ({ setIsForgotPassword }) => {
  const [isShowSignup, setIsShowSignup] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const toggleShowSignup = () => setIsShowSignup(!isShowSignup);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const initialValues = useMemo(
    () => ({
      username: '',
      password: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    username: Yup.string().required('Введите номер телефона'),
    password: Yup.string().required('Введите пароль'),
  });

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    validationSchema,
    onSubmit: (values) => {
      setIsSending(true);
      dispatch(auth(values))
        .then(() => {
          setIsSending(false);
        })
        .catch((e) => {
          setIsSending(false);

          if (e.response.status === 401) {
            message.error('Неверный логин или пароль');
          } else {
            message.error('Произошла ошибка');
          }
        });
    },
  });

  return (
    <>
      <Form
        form={form}
        onFinish={formik.handleSubmit}
        className={styles['sign-in__form']}
      >
        <FormField
          formik={formik}
          name="username"
          placeholder="Номер телефона"
          prefix={<UserOutlined />}
        />

        <FormField
          formik={formik}
          name="password"
          placeholder="Пароль"
          component={Password}
          prefix={<LockOutlined />}
        />

        <Button
          type="text"
          htmlType="button"
          onClick={setIsForgotPassword}
          className={styles['sign-in__btn-text']}
        >
          Забыли пароль?
        </Button>

        <Form.Item>
          <Button
            // disabled={!formik.isValid || isSending}
            disabled={isSending}
            type="primary"
            htmlType="submit"
            className={styles['sign-in__btn']}
          >
            Вход
          </Button>
        </Form.Item>
      </Form>

      <Button
        type="text"
        htmlType="button"
        onClick={toggleShowSignup}
        className={styles['sign-in__btn-text']}
      >
        Регистрация
      </Button>

      <SignUp toggle={toggleShowSignup} visible={isShowSignup} />
    </>
  );
};

SignInForm.propTypes = {
  setIsForgotPassword: PropTypes.func.isRequired,
};

export default SignInForm;
