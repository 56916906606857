import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import SingIn from '../components/sign/sign-in/sign-in';

import { RECAPTCHA_SITE_KEY } from '../utils/constants/shared';

const Main = () => (
  <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
    <div className="main__wrapper">
      <SingIn />
    </div>
  </GoogleReCaptchaProvider>
);

export default Main;
