import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, message,
} from 'antd';
import { MobileOutlined } from '@ant-design/icons';
import ReactInputMask from 'react-input-mask';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { FormField } from '../../../../shared';

import { resetPassword } from '../../../../../services/reset-password';

import { phoneNumberFormatter } from '../../../../../utils/formatters';

import styles from '../forgot-password-form.module.scss';

const UsernameForm = ({ setToken }) => {
  const [isSending, setIsSending] = useState(false);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const initialValues = useMemo(
    () => ({
      phone: '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    phone: Yup.string()
      .length(10, 'Введите корректный номер телефона')
      .required('Поле обязательно для заполнения'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setIsSending(true);

      const grToken = await executeRecaptcha('send_code');

      dispatch(resetPassword({
        ...values,
        grToken,
      }))
        .then((data) => {
          setIsSending(false);
          setToken(data.data.token);
        })
        .catch((e) => {
          setIsSending(false);

          const err = e.response.data.detail;

          if (err) {
            message.error(err);
          } else {
            message.error('Произошла ошибка');
          }
        });
    },
  });

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={formik.handleSubmit}
      className={styles['forgot-password__form']}
    >
      <FormField
        formik={formik}
        label="Номер телефона"
        name="phone"
        placeholder="Введите номер телефона"
        prefix={<MobileOutlined />}
        component={ReactInputMask}
        mask="+7\-(999)-999-99-99"
        maskChar="X"
        onChange={(evt) => formik.setFieldValue('phone', phoneNumberFormatter(evt.target.value))}
      >
        {(inputProps) => <Input {...inputProps} />}
      </FormField>

      <Form.Item>
        <Button
          // disabled={!formik.isValid || isSending}
          disabled={isSending}
          type="primary"
          htmlType="submit"
          className={styles['forgot-password__submit']}
        >
          Запросить код
        </Button>
      </Form.Item>
    </Form>
  );
};

UsernameForm.propTypes = {
  setToken: PropTypes.func.isRequired,
  setIsForgotPassword: PropTypes.func.isRequired,
};

export default UsernameForm;
