import humps from 'humps';

import { adaptRequestBodyPhoneField } from '../utils/helpers/shared';

const resetPassword = (values) => (dispatch, _getState, api) => api.post('/password_reset', humps.decamelizeKeys(adaptRequestBodyPhoneField(values)));

const validateCode = (values) => (dispatch, _getState, api) => api.post('/password_reset/validate_token', values);

const changePassword = (values) => (dispatch, _getState, api) => api.post('/password_reset/confirm', values);

export { resetPassword, validateCode, changePassword };
