import { useState } from 'react';
import PropTypes from 'prop-types';

import UsernameForm from './username-form/username-form';
import CodeForm from './code-form/code-form';
import ChangePasswordForm from './change-password-form/change-password-form';

const ForgotPasswordForm = ({ setIsForgotPassword }) => {
  const [token, setToken] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);

  return (
    <>
      {!token && (
        <UsernameForm
          setToken={setToken}
          setIsForgotPassword={setIsForgotPassword}
        />
      )}
      {token && !isCodeValid && (
        <CodeForm
          token={token}
          setIsCodeValid={setIsCodeValid}
          setIsForgotPassword={setIsForgotPassword}
        />
      )}
      {token && isCodeValid && (
        <ChangePasswordForm
          token={token}
          setIsForgotPassword={setIsForgotPassword}
        />
      )}
    </>
  );
};

ForgotPasswordForm.propTypes = {
  setIsForgotPassword: PropTypes.func.isRequired,
};

export default ForgotPasswordForm;
