import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { FormField } from '../../../shared';
import styles from './sms-code.module.scss';

const SMSCode = ({ formik, sendCode }) => {
  const [timeLeft, setTimeLeft] = useState(59);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((sec) => sec - 1);
    }, 1000);
    if (timeLeft <= 0) {
      return () => clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [timeLeft]);

  const formatSeconds = (seconds) => `00${seconds}`.slice(-2);

  return (
    <div>
      <FormField
        formik={formik}
        label="Код подтверждения"
        name="code"
        placeholder="Код из SMS"
      />
      {timeLeft > 0 ? (
        <div
          className={`ant-btn ant-btn-link ${styles['sms-code__counter']}`}
        >
          {`Отправить код повторно через 00:${formatSeconds(timeLeft)}`}
        </div>
      ) : (
        <Button
          type="link"
          onClick={() => {
            sendCode(formik.values, { setErrors: formik.setErrors })
              .then(() => {
                setTimeLeft(59);
              })
              .catch(() => {});
          }}
        >
          Отправить код повторно
        </Button>
      )}
    </div>
  );
};

SMSCode.propTypes = {
  formik: PropTypes.shape({
    values: PropTypes.shape({}),
    setErrors: PropTypes.func,
  }).isRequired,
  sendCode: PropTypes.func.isRequired,
};

SMSCode.defaultProps = {};

export default SMSCode;
