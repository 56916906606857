import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, message,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ReactInputMask from 'react-input-mask';

import { FormField } from '../../../../shared';

import { validateCode } from '../../../../../services/reset-password';

import { maskedNumberFieldFormatter } from '../../../../../utils/formatters';

import styles from '../forgot-password-form.module.scss';

const CodeForm = ({ token, setIsCodeValid }) => {
  const [isSending, setIsSending] = useState(false);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const initialValues = useMemo(
    () => ({
      code: '',
      token: token || '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    code: Yup.string()
      .length(4, 'Введите корректный код')
      .required('Поле обязательно для заполнения'),
    token: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setIsSending(true);
      dispatch(validateCode(values))
        .then(() => {
          setIsSending(false);
          setIsCodeValid(true);
        })
        .catch((e) => {
          setIsSending(false);

          const err = e.response.data.detail;

          if (err) {
            message.error(err);
          } else {
            message.error('Произошла ошибка');
          }
        });
    },
  });

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={formik.handleSubmit}
      className={styles['forgot-password__form']}
    >
      <FormField
        formik={formik}
        label="Код подтверждения"
        name="code"
        placeholder="Код подтверждения"
        component={ReactInputMask}
        mask="9999"
        maskChar="✱"
        onChange={(evt) => formik.setFieldValue(
          'code',
          maskedNumberFieldFormatter(evt.target.value),
        )}
        prefix={<UserOutlined />}
      >
        {(inputProps) => <Input {...inputProps} />}
      </FormField>

      <Form.Item>
        <Button
          // disabled={!formik.isValid || isSending}
          disabled={isSending}
          type="primary"
          htmlType="submit"
          className={styles['forgot-password__submit']}
        >
          Отправить код
        </Button>
      </Form.Item>
    </Form>
  );
};

CodeForm.propTypes = {
  token: PropTypes.string.isRequired,
  setIsCodeValid: PropTypes.func.isRequired,
  setIsForgotPassword: PropTypes.func.isRequired,
};

export default CodeForm;
