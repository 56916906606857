import humps from 'humps';

import { ActionCreator } from '../store/action/authorization/authorization';
import { adaptRequestBodyPhoneField } from '../utils/helpers/shared';

export const signup = (values) => (dispatch, _getState, api) => api
  .post('/registration/user', adaptRequestBodyPhoneField(values))
  .then(({ data }) => {
    dispatch(ActionCreator.login(data));
  });

export const sendCode = (body) => (dispatch, _getState, api) => api.post('/registration/send_code', humps.decamelizeKeys(adaptRequestBodyPhoneField(body)));

export const confirm = (token, code) => (dispatch, _getState, api) => api.post('/signup/confirm', { token, code });
