import humps from 'humps';

import { ActionCreator } from '../store/action/authorization/authorization';

// const auth = (values) => (dispatch, _getState, api) => (
//   api.post('/auth', values)
//     .then(({ data }) => {
//       dispatch(ActionCreator.login(data));
//     })
// );

export const auth = (body) => (dispatch, _getState, api) => (
  api.post('/auth', humps.decamelizeKeys(body))
    .then(({ data, status }) => {
      if (status === 201) {
        dispatch(ActionCreator.setSessionToken(humps.camelizeKeys(data)));
      } else {
        dispatch(ActionCreator.login(data));
      }
    })
);

export const checkCode = (body) => (dispatch, _getState, api) => api
  .post('/auth/two_factor', humps.decamelizeKeys(body))
  .then(({ data }) => {
    dispatch(ActionCreator.login(humps.camelizeKeys(data)));
  });

export const checkRestoreCode = (body) => (dispatch, _getState, api) => api
  .post('/auth/two_factor/code', humps.decamelizeKeys(body))
  .then(({ data }) => {
    dispatch(ActionCreator.login(humps.camelizeKeys(data)));
  });
