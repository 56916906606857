import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button, Form, Input, message,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';

import { FormField } from '../../../shared';

import {
  checkCode,
  checkRestoreCode,
} from '../../../../services/authorization';

import { maskedNumberFieldFormatter } from '../../../../utils/formatters';

import styles from './check-code.module.scss';
import { ActionCreator } from '../../../../store/action/authorization/authorization';

const CheckCode = ({ sessionToken }) => {
  const [isSending, setIsSending] = useState(false);
  const [isForgotCode, setIsForgotCode] = useState(false);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const initialValues = useMemo(
    () => ({
      code: '',
      sessionToken: sessionToken || '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    code: Yup.string()
      .test({
        name: 'Length',
        test: (value) => (!isForgotCode && value ? value.length === 6 : true),
        message: 'Введите корректный код',
      })
      .required('Поле обязательно для заполнения'),
    sessionToken: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setIsSending(true);
      dispatch(isForgotCode ? checkRestoreCode(values) : checkCode(values))
        .then(() => {
          setIsSending(false);
        })
        .catch((e) => {
          setIsSending(false);

          const err = e.response.data.detail
            || e.response.data.non_field_errors
            || e.response.data.code;

          if (err) {
            message.error(typeof err === 'object' ? err[0] : err);
          } else {
            message.error('Произошла ошибка');
          }
        });
    },
  });

  useEffect(() => {
    if (!isForgotCode && formik.values.code.length === 6) {
      formik.submitForm();
    }
  }, [formik.values.code]);

  const handleRestoreCodeButtonClick = () => {
    formik.setFieldValue('code', '');
    form.setFieldsValue({ code: '' });
    setIsForgotCode(true);
  };

  const handleBackToLoginButtonClick = () => {
    dispatch(ActionCreator.setSessionToken({ sessionToken: null }));
  };

  const handleBackToCheckCodeButtonClick = () => {
    formik.setFieldValue('code', '');
    form.setFieldsValue({ code: '' });
    setIsForgotCode(false);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={formik.handleSubmit}
      className={styles['check-code__form']}
    >
      {!isForgotCode && (
        <>
          <FormField
            formik={formik}
            label="Проверочный код"
            name="code"
            placeholder="Введите проверочный код"
            component={ReactInputMask}
            mask="999999"
            maskChar="✱"
            onChange={(evt) => formik.setFieldValue(
              'code',
              maskedNumberFieldFormatter(evt.target.value),
            )}
            prefix={<UserOutlined />}
            autoFocus
          >
            {(inputProps) => <Input {...inputProps} />}
          </FormField>

          <p className={styles['check-code__note']}>
            Введите код из вашего приложения для двухфакторной аутентификации.
            Если вы потеряли свое устройство, вы можете ввести один из своих
            кодов восстановления.
          </p>

          <Form.Item>
            <Button
              // disabled={!formik.isValid || isSending}
              disabled={isSending}
              type="primary"
              htmlType="submit"
              className={styles['check-code__submit']}
            >
              Проверить код
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              // disabled={!formik.isValid || isSending}
              disabled={isSending}
              type="primary"
              onClick={handleRestoreCodeButtonClick}
              className={styles['check-code__submit']}
            >
              Ввести код восстановления
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              // disabled={!formik.isValid || isSending}
              disabled={isSending}
              type="default"
              onClick={handleBackToLoginButtonClick}
              className={styles['check-code__backward']}
            >
              Назад
            </Button>
          </Form.Item>
        </>
      )}

      {isForgotCode && (
        <>
          <FormField
            formik={formik}
            label="Код восстановления доступа"
            name="code"
            placeholder="Введите код восстановления"
            prefix={<UserOutlined />}
          />

          <Form.Item>
            <Button
              // disabled={!formik.isValid || isSending}
              disabled={isSending}
              type="primary"
              htmlType="submit"
              className={styles['check-code__submit']}
            >
              Проверить код
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              // disabled={!formik.isValid || isSending}
              disabled={isSending}
              type="default"
              onClick={handleBackToCheckCodeButtonClick}
              className={styles['check-code__backward']}
            >
              Назад
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

CheckCode.propTypes = {
  sessionToken: PropTypes.string.isRequired,
};

export default CheckCode;
