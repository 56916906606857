import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  Button, Form, Input, message,
} from 'antd';
import { LockOutlined } from '@ant-design/icons';

import { FormField } from '../../../../shared';

import { changePassword } from '../../../../../services/reset-password';

import styles from '../forgot-password-form.module.scss';

const { Password } = Input;

const ChangePasswordForm = ({ token, setIsForgotPassword }) => {
  const [isSending, setIsSending] = useState(false);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const initialValues = useMemo(
    () => ({
      password: '',
      confirmPassword: '',
      token: token || '',
    }),
    [],
  );

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Поле обязательно для заполнения')
      .min(6, 'Пароль должен содержать минимум 8 символов.')
      .matches(/[a-zA-Z]/, 'Введённый пароль состоит только из цифр'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
      .required('Поле обязательно для заполнения'),
    token: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      ...initialValues,
    },
    validationSchema,
    onSubmit: (values) => {
      setIsSending(true);

      delete values.confirmPassword;

      dispatch(changePassword(values))
        .then(() => {
          setIsSending(false);
          setIsForgotPassword(false);
          message.success('Пароль успешно изменен');
        })
        .catch((e) => {
          setIsSending(false);

          const err = e.response.data.detail;

          if (err) {
            message.error(err);
          } else {
            message.error('Произошла ошибка');
          }
        });
    },
  });

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={formik.handleSubmit}
      className={styles['forgot-password__form']}
    >
      <FormField
        formik={formik}
        label="Новый пароль"
        name="password"
        placeholder="Введите пароль"
        component={Input.Password}
        prefix={<LockOutlined />}
      />

      <FormField
        formik={formik}
        label="Пароль повторно"
        name="confirmPassword"
        placeholder="Введите пароль еще раз"
        component={Password}
        prefix={<LockOutlined />}
      />

      <Form.Item>
        <Button
          // disabled={!formik.isValid || isSending}
          disabled={isSending}
          type="primary"
          htmlType="submit"
          className={styles['forgot-password__submit']}
        >
          Сохранить пароль
        </Button>
      </Form.Item>
    </Form>
  );
};

ChangePasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
  setIsForgotPassword: PropTypes.func.isRequired,
};

export default ChangePasswordForm;
